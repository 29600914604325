 <template>
	<el-dialog title="创建订单批次/导入订单" top="1vh" width="60%" :visible.sync="is_show_in_page">
		
		<!-- 表单 -->
		<el-form class="form" label-width="80px">
			<div class="big_tit" style="margin:0">基本数据</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="选择客户" style="width:60%">
					<el-select class="el_inner_width" v-model="form.customer_user_num" @change="customer_user_change" :disabled="excel_loading" clearable>
						<el-option 
							v-for="(item,index) of customer_user_list" 
							:key="index" :value="item.customer_user_num" 
							:label="`${item.tel}/${item.user_name}/${item.company_name}`" 
						></el-option>
					</el-select>
				</el-form-item>
				<el-form-item class="el_form_item" label="备注" style="width:40%">
					<el-input class="el_inner_width" v-model="form.mark" placeholder="必填" clearable></el-input>
				</el-form-item>
			</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="车牌号" style="width:60%">
					<el-input class="el_inner_width" v-model="form.truck_plate_nums" placeholder="多个车牌号用英文逗号(,)隔开,例如:浙C12345,浙C12345" clearable></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">
				<div>Excel文件</div>
				<el-button @click="excel_load" type="primary" size="mini" style="position:absolute;left:110px;top:8px">选择</el-button>
			</div>
			<div class="tab_height">
				<el-table :data="excel_list" :border="true" :stripe="true" size="small" height="100%">
					<el-table-column label="文件名称" prop="file_name"></el-table-column>
					<el-table-column label="订单条数" width="110">
						<template slot-scope="scope">
							{{scope.row.file_data.length-scope.row.data_start_column}}
						</template>
					</el-table-column>
					<el-table-column label="操作" width="110">
						<template slot-scope="scope">
							<el-button @click="excel_del(scope.$index)" size="mini" type="text">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-form>

		<div class="bottom_btns">
			<el-button type="primary" @click="sub">确定添加</el-button>
			<el-button type="danger" @click="clear">清空数据</el-button>
		</div>

		<!-- 读取excel弹出层 -->
		<el-dialog 
			title="上传进度" 
			width="600px" 
			:close-on-click-modal="false" 
			:close-on-press-escape="false" 
			:show-close="false" 
			append-to-body 
			custom-class="el-dialog-s" 
			:visible.sync="sub_progress.is_show" 
		>
			<div style="margin-top:-20px">
				<div v-if="sub_progress.step==1" style="text-align: center;height: 80px;line-height: 80px;">批次创建中</div>
				<div v-if="sub_progress.step==2" style="text-align: center;height: 80px;line-height: 80px;">
					文件总数:{{sub_progress.file_total}} / 已上传:{{sub_progress.file_uploaded_total}} / 第{{sub_progress.file_uploading+1}}个文件上传中...
				</div>
			</div>
		</el-dialog>

	</el-dialog>
</template>

<script>
	import xlsx from 'xlsx'
	export default {
		props:{
			is_show:Number,
			customer_user_list:Array,
			pretreatment_list:Array,
		},
		data() {
			return {
				
				//是否显示
				is_show_in_page:false,
				pretreatment_list_obj:{},
				sub_swh:false,

				//表单
				form:{
					truck_plate_nums:'',
					customer_user_num:'',
					mark:'',
				},

				//已被选定的客户
				customer_user_choosed:{},

				//已选择文件列表
				excel_list:[],

				//excel加载状态
				excel_loading:false,

				//数据提交指示器
				sub_progress:{
					is_show:false,
					step:1,

					file_total:0,
					file_uploaded_total:0,
					file_uploading:0,
				},
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开弹出层
					this.is_show_in_page=true;

					//预处理模板
					let pretreatment_list_obj={};
					for(var item of this.pretreatment_list){
						pretreatment_list_obj[item.pretreatment_method]=item
					}
					this.pretreatment_list_obj=pretreatment_list_obj
					
					//初始化
					this.init();

				}else{

					//关闭弹出层
					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			//提交
			sub(){

				if(this.sub_swh){
					this.$my.other.msg({
						type:'warning',
						str:"提交中,请稍后..."
					});
					return;
				}

				if(!this.customer_user_choosed.customer_user_num){
					this.$my.other.msg({
						type:'warning',
						str:"请先选择客户"
					});
					return;
				}

				if(this.excel_list.length==0){
					this.$my.other.msg({
						type:'warning',
						str:"请上传至少一个excel"
					});
					return;
				}

				//车牌号
				let truck_plate_nums=this.form.truck_plate_nums.split(',')
				let truck_plate_num_list=[];
				for(var item of truck_plate_nums){
					if(!item.trim()){
						continue;
					}
					if(!this.$my.check.is_plate_num(item.trim())){
						this.$my.other.msg({
							type:'warning',
							str:`车牌号(${item})格式有误,请检查车牌号格式,或者检查逗号是否正确`
						});
						return;
					}
					if(truck_plate_num_list.indexOf(item.trim())!=-1){
						this.$my.other.msg({
							type:'warning',
							str:`车牌号(${item})已经存在`
						});
						return;
					}
					truck_plate_num_list.push(item.trim())
				}

				//开始提交
				this.sub_swh=true;
				this.sub_progress.is_show=true;
				this.sub_progress.step=1

				//调用接口
				this.$my.net.req({
					data:{
						mod:'tord',
						ctr:'tord_batch_add_by_admin',
						truck_plate_num_list:truck_plate_num_list,
						...this.form
					},
					callback:(data)=>{

						//缓存原始订单批次编号
						this.tord_batch_num=data.tord_batch_num

						//报告结果
						this.$my.other.msg({
							type:'success',
							str:"批次创建成功"
						});

						//进入第二步
						this.sub_progress.step=2
						this.sub_progress.file_total=this.excel_list.length;//文件总数
						this.sub_progress.file_uploaded_total=0;//已上传文件数量

						//开始上传运单数据
						this.file_upload(0);
					}
				});
			},
			file_upload(file_index){

				//正在上传第N个文件
				this.sub_progress.file_uploading=file_index

				//取出待上传文件
				let file=this.excel_list[file_index]

				//开始上传
				this.$my.net.req({
					method:'post',
					data:{
						mod:'tord',
						ctr:'tord_add_by_admin',
						tord_batch_num:this.tord_batch_num,
						customer_user_num:this.form.customer_user_num,
						pretreatment_method:this.customer_user_choosed.pretreatment_method,
						...file,
					},
					callback:(data)=>{

						//已上传文件+1
						this.sub_progress.file_uploaded_total++

						//如果下一个文件不存在则停止上传,并报告结果
						if(this.excel_list.length==file_index+1){

							//关闭弹出层
							this.sub_progress.is_show=false;

							//关闭弹出层
							this.is_show_in_page=false;
							
							//报告结果
							this.$my.other.msg({
								type:'success',
								str:"上传完毕"
							});

							//通知
							this.$emit("added");

						}else{

							//继续上传
							this.file_upload(file_index+1);
						}
					}
				});
			},


			//运单导入相关
			excel_del(index){
				this.excel_list.splice(index,1)
			},
			excel_load(){//导入运单表格

				// this.$my.file.choose({
				// 	accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				// 	success: (res) => {

				// 		this.$my.other.msg({
				// 			type:'success',
				// 			str:"文件已读取,开始分析文件"
				// 		});
				// 		this.excel_loading=true;

				// 		let file_reader = new FileReader();
				// 		file_reader.onload = (ev) => {
				// 			let workbook = xlsx.read(ev.target.result, {
				// 				type: 'binary'
				// 			})

				// 			//取出数据
				// 			let file_data = xlsx.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]],{header:1,defval:''})//生成json表格内容

				// 			file_data.splice(0,1)

				// 			let agent={};

				// 			for(var item of file_data){

				// 				if(!agent[item[4]]){
				// 					agent[item[4]]={
				// 						link_man:item[8],
				// 						link_tel:item[9],
				// 						prov:item[12],
				// 						city:item[13],
				// 						county:item[14],
				// 						addr:item[10],
				// 						match_addr:item[11],
				// 						mark:`经销商编号:${item[0]},专卖店编号:${item[1]},经销商:${item[3]},专卖店电话:${item[6]}`,
				// 					}
				// 				}

				// 				// if(!route[item[10]]){
				// 				// 	route[item[10]]={
				// 				// 		company:item[1],
				// 				// 		case_node:item[8],
				// 				// 		aim_node:item[9],
				// 				// 	}
				// 				// }

				// 				// if(!node[item[8]]){
				// 				// 	node[item[8]]={
				// 				// 		company:item[1],
				// 				// 		park:item[2],
				// 				// 		link_tel:item[11].length==11?item[11]:(item[11].length==8?`0579${item[11]}`:'')
				// 				// 	}
				// 				// }
				// 				// if(!node[item[9]]){
				// 				// 	node[item[9]]={
				// 				// 		company:item[1],
				// 				// 		park:item[7],
				// 				// 		link_tel:item[13]
				// 				// 	}
				// 				// }

				// 				// if(company.indexOf(item[1])==-1){
				// 				// 	company.push(item[1])
				// 				// }

				// 				// if(!parks[item[2]]){
				// 				// 	parks[item[2]]={
				// 				// 		prov:item[3],
				// 				// 		city:item[4],
				// 				// 		county:item[5],
				// 				// 		addr:item[6],
				// 				// 	}
				// 				// }
				// 				// if(!parks[item[7]]){
				// 				// 	parks[item[7]]={
				// 				// 		prov:item[14],
				// 				// 		city:item[15],
				// 				// 		county:item[16],
				// 				// 		addr:item[12].trim()?item[12].trim():item[16]+'政府',
				// 				// 	}
				// 				// }
				// 			}

				// 			console.log(agent);
				// 			return;

				// 			this.$my.net.req({
				// 				method:'post',
				// 				data:{
				// 					mod:'customer',
				// 					ctr:'agent_add_by_admin',
				// 					agent:agent,
				// 				},
				// 				callback:(data)=>{

				// 					console.log(data)
				// 				}
				// 			});


				// 		}
				// 		file_reader.readAsBinaryString(res.file)
				// 	}
				// })

				// return;


				//必须先选择客户
				if(!this.customer_user_choosed.customer_user_num){
					this.$my.other.msg({
						type:'warning',
						str:"请先选择客户"
					});
					return;
				}

				//检查模板是否被定义
				if(!this.pretreatment_list_obj[this.customer_user_choosed.pretreatment_method]){
					this.$my.other.msg({
						type:'warning',
						str:"所选择的客户未配置数据字典,请联系开发人员"
					});
					return;
				}

				//读取中...
				if(this.excel_loading){
					this.$my.other.msg({
						type:'warning',
						str:"加载中,请稍后"
					});
					return;
				}

				this.$my.file.choose({
					accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
					success: (res) => {

						this.$my.other.msg({
							type:'success',
							str:"文件已读取,开始分析文件"
						});
						this.excel_loading=true;

						let file_reader = new FileReader();
						file_reader.onload = (ev) => {
							let workbook = xlsx.read(ev.target.result, {
								type: 'binary'
							})

							//取出数据
							let file_data = xlsx.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]],{header:1,defval:''}) // 生成json表格内容

							//取出模板
							let pretreatment=this.pretreatment_list_obj[this.customer_user_choosed.pretreatment_method]

							//置入数据
							this.excel_list.push({
								file_name:res.file.name,
								data_start_column:pretreatment.data_start_column,
								file_data:file_data
							});

							//报告结果
							this.$my.other.msg({
								type:'success',
								str:"文件已分析完毕"
							});
							this.excel_loading=false;
						}
						file_reader.readAsBinaryString(res.file)
					}
				})
			},

			//客户选定变化
			customer_user_change(){
				for(var item of this.customer_user_list){
					if(item.customer_user_num==this.form.customer_user_num){
						this.customer_user_choosed=item
						break;
					}
				}

				//已选择文件列表
				this.excel_list=[];
			},

			//初始化
			init(){

				//解除提交锁
				this.sub_swh=false
				
				//清空表单数据
				this.form.customer_user_num="";
				this.form.mark="";

				//清空已选定客户
				this.customer_user_choosed={}

				//已选择文件列表
				this.excel_list=[];

				//清空excel进度显示
				this.excel_loading=false;

				//数据提交指示器
				this.sub_progress={
					is_show:false,
					step:1,
					file_total:0,
					file_uploaded_total:0,
					file_uploading:0,
				};
			},

			//清空本页数据
			clear(){

				this.$my.other.confirm({
					content:"点击确定清空本页数据",
					confirm:()=>{

						//初始化
						this.init();
					}
				});
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
		position:relative;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}
	.el_inner_div{
		cursor: pointer;
	}

	.bottom_btns{
		text-align: center;
		position: absolute;
		bottom: 0px;
		width: calc(100% - 60px);
		padding: 15px 0;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form{
		height: calc(100% - 100px);
		overflow-y:auto;
		padding-bottom:75px;
		margin-top:-20px;
	}

	.tab_height{
		height:300px;
	}

	.page{
		position:relative;
		background-color: #fff;
		border-radius: 10px;
		height: 100%;
		width: 1040px;
		overflow: auto;
		margin: auto;
	}

	.el-dialog-s{
		z-index: 15;
	}

</style>