 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="80px">
			<el-form-item class="el_form_item" label="客户名称">
				<el-select class="el_input" v-model="form.customer_user_num" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option v-for="(item,index) of customer_user_list" :key="index" :label="`${item.user_name}/${item.company_name}`" :value="item.customer_user_num"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="备注">
				<el-input class="el_input" v-model="form.mark" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="状态">
				<el-select class="el_input" v-model="form.status" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="未匹配完毕" value="1"></el-option>
					<el-option label="匹配完毕" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="success" @click="add_view_open">添加</el-button>
				<el-button type="warning" @click="statistics_all">统计全部</el-button>
				<el-button type="warning" @click="match_all">匹配全部</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="添加/修改时间" width="190">
					<template slot-scope="scope">
						<div class="tab_line_item">编号: {{scope.row.tord_batch_num}}</div>
						<div class="tab_line_item">添加: {{scope.row.create_time_text}}</div>
						<div class="tab_line_item">修改: {{scope.row.update_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作人" width="100">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.admin_user_info_import.tel}}</div>
						<div class="tab_line_item">{{scope.row.admin_user_info_import.name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="客户信息" width="200">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.customer_user_info.tel}}/{{scope.row.customer_user_info.user_name}}</div>
						<div class="tab_line_item">{{scope.row.customer_user_info.company_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="运单数量" width="280">
					<template slot-scope="scope">
						<el-button size="mini" type="text" @click="to_tord_list(scope.row)">全部:{{scope.row.tord_quantity_total}}</el-button>
						<el-button 
							v-for="(quantity,status_name) in scope.row.tord_quantity_info" 
							:key="status_name" size="mini" type="text" @click="to_tord_list(scope.row,status_name)"
						>{{status_name}}:{{quantity}}</el-button>
					</template>
				</el-table-column>
				<el-table-column label="原始内容汇总">
					<template slot-scope="scope">
						<el-tag 
							v-for="(quantity,cargo_name) in scope.row.cargo_origin_contents" 
							:key="cargo_name"
						>{{cargo_name}}:{{quantity}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="匹配后货物汇总">
					<template slot-scope="scope">
						<el-tag 
							v-for="(quantity,cargo_name) in scope.row.cargo_changed_contents" 
							:key="cargo_name"
						>{{cargo_name}}:{{quantity}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="关联车牌号" prop="truck_plate_nums" width="90"></el-table-column>
				<el-table-column label="备注" prop="mark" width="140"></el-table-column>
				<el-table-column label="状态" prop="status_text" width="100"></el-table-column>
				
				
				<!-- 操作行 -->
				<el-table-column label="操作" width="140">
					<template slot-scope="scope">
						<el-button @click="statistics(scope.row)" size="mini" type="text">统计</el-button>
						<el-button @click="match(scope.row)" size="mini" type="text" >匹配</el-button>
						<el-button v-if="scope.row.status==2 || scope.row.status==3" @click="make_tally_bill(scope.row)" size="mini" type="text" >理货单</el-button>
						<el-button @click="edit_view_open(scope.row)" type="text" style="margin:0" size="mini">车牌号</el-button>
						<el-button v-if="scope.row.status==2 || scope.row.status==3" @click="tord_export(scope.row)" type="text" size="mini">导出运单</el-button>
						<el-button @click="del(scope.row)" size="mini" type="text" style="margin:0">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change" 
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]" 
			:page-size="page.num" 
			:total="list.total*1" 
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>

		<!-- 添加弹出层 -->
		<add 
			:is_show="add.is_show" 
			:customer_user_list="customer_user_list" 
			:pretreatment_list="pretreatment_list" 
			@added="add_view_close"
		></add>

		<!-- 修改弹出层 -->
		<edit 
			:is_show="edit.is_show" 
			:data="edit.data" 
			@edited="edit_view_close" 
		></edit>

		<!-- 匹配/统计指示器 -->
		<el-dialog 
			:title="window.name" 
			width="600px" 
			:close-on-click-modal="false" 
			:close-on-press-escape="false" 
			:show-close="false" 
			:visible.sync="window.is_show" 
		>
			<div style="text-align: center;height: 80px;line-height: 80px;margin-top:-20px">
				批次号:{{window.tord_batch_num}} / 状态:{{window.status}}
			</div>
		</el-dialog>

		<!-- 导出理货清单 -->
		<tallyBill 
			:is_show="tally_bill.is_show" 
			:tord_batch_info="tally_bill.tord_batch_info" 
		></tallyBill>

	</div>
</template>

<script>
	import add from './add.vue' 
	import edit from './edit.vue' 
	import tallyBill from './tally_bill.vue' 
	export default {
		components:{
			add,
			edit,
			tallyBill,
		},
		data() {
			return {

				//搜索条件
				form: {
					customer_user_num:'',
					mark:'',
					status:'',
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//基础数据
				customer_user_list:[],
				pretreatment_list:[],

				//添加弹窗
				add:{
					is_show:0,
				},

				//修改
				edit:{
					data:{},
					is_show:0,
				},

				//导出理货清单
				tally_bill:{
					tord_batch_info:{},
					is_show:0,
				},

				//匹配/统计指示器
				window:{
					name:'',
					tord_batch_num:"",
					status:"",
					is_show:false,
				},
			}
		},
		created() {

			//读取客户列表
			this.get_customer_user_list()
			
			//获取预处理模板列表
			this.get_pretreatment_list();

			//读取用户组数据
			this.get_page_data()
		},
		methods: {
			
			//导出搜索结果
			tord_export_doing(para){

				this.$my.net.req({
					data:{
						mod:'tord',
						ctr:'tord_batch_export_by_admin',
						...para
					},
					callback:(data)=>{

						//如果是 整理后导出 则刷新页面数据
						if(para.export_type==2){
							this.get_page_data();
						}

						//整理数据
						let tord_export_list={};
						for(var tord_item of data.list){

							//货物名称
							tord_item.cargo_changed_contents=JSON.parse(tord_item.cargo_changed_contents)
							
							//剔除无效单
							if(this.$my.check.is_arr(tord_item.cargo_changed_contents) && tord_item.cargo_changed_contents.length==0){
								continue
							}

								
							if(!tord_export_list[tord_item.tord_export_num]){
								tord_export_list[tord_item.tord_export_num]=[];
							}
							tord_export_list[tord_item.tord_export_num].push(tord_item)
						}

						//二次整理
						let excel_data=[];
						for(var tord_export_num in tord_export_list){

							//取出其中一组数据
							let tord_export_item=tord_export_list[tord_export_num]

							//把一组数据合并为导出需要的格式
							let excel_data_item={
								运单号:tord_export_num,
								委托单号:[],
								开单时间:this.$my.other.todate(tord_export_item[0].export_time),
								付款方式:'月结',
								经办人:tord_export_item[0].admin_user_info_export.name,
								送货方式:'自提',
								货号:tord_export_num.substring(6,11),
								到站:tord_export_item[0].tord_match_addr_show,
								发货单位:tord_export_item[0].customer_user_info.company_name,
								发货人:tord_export_item[0].customer_user_info.user_name,
								发货人手机:tord_export_item[0].customer_user_info.tel,
								收货单位:tord_export_item[0].customer_agent_info.name,
								收货人:tord_export_item[0].customer_agent_info.link_man,
								收货人手机:tord_export_item[0].customer_agent_info.link_tel,
								收货地址:tord_export_item[0].customer_agent_info.addr,
								货物名称:[],
								货物代码:[],
								货物类型:[],
								规格:[],
								型号:'',
								件数:[],
								体积:[],
								重量:[],
								开单运费:'',
								运单备注:'',
								发货备注:tord_export_item[0].tord_node_list[0].logistics_park_name,
								收货备注:tord_export_item[0].logistics_company_info.name,
								收货地址备注:tord_export_item[0].tord_node_list[0].mark,
								收货电话:tord_export_item[0].tord_node_list[0].link_tel,
								内部备注:[],
								预算其他费:'',
							};
							let tord_origin_batch_nums=[];
							let tord_export_cargo_total={};
							for(var tord_item of tord_export_item){

								let cargo_changed_contents=tord_item.cargo_changed_contents
								let cargo_changed_contents_str=""
								for(var key in cargo_changed_contents){
									
									//
									cargo_changed_contents_str+=`${key}${cargo_changed_contents[key]}`

									//规格
									if(!tord_export_cargo_total[key]){
										tord_export_cargo_total[key]=parseInt(cargo_changed_contents[key])
									}else{
										tord_export_cargo_total[key]+=parseInt(cargo_changed_contents[key])
									}

								}
								excel_data_item.货物名称.push(cargo_changed_contents_str)
								
								//批号
								tord_origin_batch_nums.push(tord_item.tord_origin_batch_num)
								// excel_data_item.委托单号.push(tord_item.tord_origin_batch_num)

								//货物类型
								excel_data_item.货物类型.push('门业')

								//件数
								excel_data_item.件数.push(tord_item.cargo_number);
								
								//体积
								excel_data_item.体积.push(tord_item.cargo_volume);
								
								//重量
								excel_data_item.重量.push(tord_item.cargo_weight);
							}

							//规格
							let 规格=""
							for(var key in tord_export_cargo_total){
								规格+=`${key}${tord_export_cargo_total[key]}`
							}
							excel_data_item.规格.push(规格);
							for(var i=0;i<tord_export_item.length-1;i++){
								excel_data_item.规格.push("");
							}

							//内部备注
							excel_data_item.内部备注=规格
							excel_data_item.委托单号=规格

							//转格式
							excel_data_item.货物名称=excel_data_item.货物名称.join('，')//货物名称
							excel_data_item.货物类型=excel_data_item.货物类型.join('，')//货物类型
							excel_data_item.规格=excel_data_item.规格.join('，')//货物类型
							excel_data_item.货物代码=tord_origin_batch_nums.join('，')
							// excel_data_item.委托单号=excel_data_item.委托单号.join('')
							excel_data_item.件数=excel_data_item.件数.join('，')
							excel_data_item.体积=excel_data_item.体积.join('，')
							excel_data_item.重量=excel_data_item.重量.join('，')

							//置入结果集
							excel_data.push(excel_data_item)
						}

						//生成excel
						this.make_csv(excel_data);
					},
				});
			},
			make_csv(data){

				var shunxu=[
					"运单号","委托单号","开单时间","付款方式","经办人","送货方式","货号","到站","发货单位","发货人",
					"发货人手机","收货单位","收货人","收货人手机","收货地址","货物名称","货物代码","货物类型","规格",
					"型号","件数","体积","重量","开单运费","运单备注","发货备注","收货备注","收货地址备注","收货电话",
					"内部备注","预算其他费"
				],str="";

				for(var colume of shunxu){
					str+=colume+",";
				}
				str+="\n";
				for(var item of data){
					for(var colume of shunxu){
						str+=`${item[colume].trim().replace('\t',"").replace('\r',"").replace('\n',"")}\t,`;
					}
					str+='\r\n';
				}
				str = "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
				
				//生成下载链接
				let link = document.createElement('a');
				link.href = str;

				//对下载的文件命名
				link.download = "运单列表.csv";

				//下载
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			},
			tord_export(item){//入口

				//未匹配
				if(item.status==1){
					this.$my.other.msg({
						type:'warning',
						str:'运单未匹配'
					});

				//已匹配完成
				}else if(item.status==2){

					//调用导出函数
					this.tord_export_doing({
						tord_batch_num:item.tord_batch_num,
						export_type:2,//1:导出上次结果,2:整理后导出
					});

				//已导出完成过
				}else if(item.status==3){
					
					//询问
					this.$my.other.confirm({
						content:"该批次已经导出过,选择导出方式",
						btns:['导出上次结果','整理后导出'],
						confirm:()=>{
							
							//调用导出函数
							this.tord_export_doing({
								tord_batch_num:item.tord_batch_num,
								export_type:1,//导出上次结果
							});
						},
						cancel:()=>{
							
							//调用导出函数
							this.tord_export_doing({
								tord_batch_num:item.tord_batch_num,
								export_type:2,//整理后导出
							});
						},
					})
				}


				

				// //读取总数量
				// this.$my.net.req({
				// 	data:{
				// 		mod:'tord',
				// 		ctr:'tord_export_by_admin',
				// 		only_num:1,
				// 		...this.rull,
				// 	},
				// 	callback:(data)=>{

				// 		//如果总数量为0
				// 		if(data.max==0){
				// 			this.$my.other.msg({
				// 				type:"warning",
				// 				str:'该条件下数据为空'
				// 			});
				// 			return;
						
				// 		//置入总数量
				// 		}else this.max=data.max;

				// 		//已读取数据
				// 		this.buf=[];

				// 		//开始读取
				// 		this.read();
				// 	}
				// })
			},

			//修改页面
			edit_view_open(item){//打开

				//置入数据
				this.edit.data=item

				//打开页面
				this.edit.is_show++;
			},
			edit_view_close(){//关闭
				
				//关闭页面
				this.edit.is_show=0

				//刷新页面
				this.get_page_data()
			},

			//生成理货单
			make_tally_bill(item){
				this.tally_bill.tord_batch_info=item
				this.tally_bill.is_show++
			},

			//前往运单列表
			to_tord_list(item,status_name){

				let query={
					tord_batch_num:item.tord_batch_num
				}
				switch(status_name){
					case '未匹配':query.status=1;break;
					case '匹配成功':query.status=2;break;
					case '匹配失败':query.status=3;break;
				}

				//跳转
				this.$router.push({
					path:'/pages/tord/tord_list',
					query:query
				});
			},

			//统计运单数据
			statistics_all(){

				//读取所有未匹配完成的运单批次
				this.$my.net.req({
					data:{
						mod:'tord',
						ctr:'tord_batch_list_by_admin',
						status:1,
						num:1000000,
					},
					callback:(data)=>{

						//开始匹配
						let that=this;
						let act=function(index){
							if(data.list[index]){
								that.statistics(data.list[index],()=>{
									act(index+1)
								});
							}else{
								that.window.is_show=false;
								that.get_page_data()
							}
						}
						act(0)
					}
				})
			},
			statistics(item,callback){

				//统计中
				this.window.is_show=true;
				this.window.status="匹配中";
				this.window.tord_batch_num=item.tord_batch_num;

				this.$my.net.req({
					data:{
						mod:'tord',
						ctr:'tord_batch_statistics_by_admin',
						tord_batch_num:item.tord_batch_num,
					},
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});

						this.window.status="统计完成";

						//回调
						if(this.$my.check.is_fun(callback)){
							callback()
						}else{
							this.window.is_show=false;
							this.get_page_data()
						}
					},
				});
			},

			//匹配所有批次
			match_all(){

				//读取所有未匹配完成的运单批次
				this.$my.net.req({
					data:{
						mod:'tord',
						ctr:'tord_batch_list_by_admin',
						status:1,
						num:1000000,
					},
					callback:(data)=>{

						//开始匹配
						let that=this;
						let act=function(index){
							if(data.list[index]){
								that.match(data.list[index],()=>{
									act(index+1)
								});
							}else{
								that.window.is_show=false;
								that.get_page_data()
							}
						}
						act(0)
					}
				})
			},
			match(item,callback){

				//匹配中
				this.window.is_show=true;
				this.window.name="匹配进度";
				this.window.status="匹配中";
				this.window.tord_batch_num=item.tord_batch_num;

				this.$my.net.req({
					data:{
						mod:'tord',
						ctr:'tord_batch_match_by_admin',
						tord_batch_num:item.tord_batch_num,
					},
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});

						this.window.status="匹配完成";

						//回调
						if(this.$my.check.is_fun(callback)){
							callback()
						}else{
							this.window.is_show=false;
							this.statistics(item)
						}
					},
				});
			},

			//删除用户组
			del(item){

				//询问
				this.$my.other.confirm({
					content:"删除整批运单?",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'tord',
								ctr:'tord_batch_del_by_admin',
								tord_batch_num:item.tord_batch_num
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.get_page_data()
							},
						});
					}
				});
			},

			//添加页面
			add_view_open(){
				this.add.is_show++
			},
			add_view_close(){
				this.add.is_show=0
				this.get_page_data()
			},

			//搜索
			page_ser(){
	
				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取用户组数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'tord',
						ctr:'tord_batch_list_by_admin',
						is_get_admin_user_info:1,
						is_get_customer_user_info:1,
						...this.form,
						...this.page
					},
					callback:(data)=>{

						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//创建时间
							item.create_time_text=this.$my.other.totime(item.create_time);

							//上次修改时间
							item.update_time_text=this.$my.other.totime(item.update_time);

							//状态
							switch(item.status){
								case '1':item.status_text="未匹配完毕";break;
								case '2':item.status_text="匹配完毕";break;
								case '3':item.status_text="已导出";break;
							}

							//运单数量信息(json)
							item.tord_quantity_info=JSON.parse(item.tord_quantity_info)
							item.tord_quantity_total=0;
							for(var status_name in item.tord_quantity_info){
								item.tord_quantity_total+=parseInt(item.tord_quantity_info[status_name]);
							}

							//转换前货物汇总(json)
							item.cargo_origin_contents=JSON.parse(item.cargo_origin_contents)

							//转换后货物汇总(json)
							item.cargo_changed_contents=JSON.parse(item.cargo_changed_contents)

							//关联车牌号
							item.truck_plate_nums=item.truck_plate_num_list?JSON.parse(item.truck_plate_num_list).join(' '):''
						}

						//渲染
						this.list.data=data.list
					}
				})
			},
			
			//获取预处理模板列表
			get_pretreatment_list(){

				//读取数据
				this.$my.net.req({
					data:{
						mod:'customer',
						ctr:'pretreatment_list_by_admin',
					},
					callback:(data)=>{

						this.pretreatment_list=data.list
					}
				});
			},
			
			//读取客户列表
			get_customer_user_list(){

				//读取数据
				this.$my.net.req({
					data:{
						mod:'customer',
						ctr:'user_list_by_admin',
						status:1,
						num:10000
					},
					callback:(data)=>{

						this.customer_user_list=data.list
					}
				});
			},
		}
	}
</script>

<style lang="scss" scoped>

	//修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		width: 90%;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 90px);
	}
	.el_form_item{
		width: 20%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>