 <template>
	<el-dialog title="修改关联车牌号" top="1vh" width="600px" :visible.sync="is_show_in_page">
		
		<!-- 表单 -->
		<el-form class="form" label-width="80px">
			<el-form-item label="车牌号">
				<el-input v-model="form.truck_plate_nums" placeholder="多个车牌号用英文逗号(,)隔开,例如:浙C12345,浙C12345" clearable></el-input>
			</el-form-item>
			<el-form-item label="备注">
				<el-input v-model="form.mark" clearable></el-input>
			</el-form-item>
			<el-form-item >
				<el-button type="primary" @click="sub">提交</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
	import xlsx from 'xlsx'
	export default {
		props:{
			is_show:Number,
			data:Object,
		},
		data() {
			return {
				
				//是否显示
				is_show_in_page:false,

				//表单
				form:{
					truck_plate_nums:"",//车牌号
					mark:"",//备注
				}
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开弹出层
					this.is_show_in_page=true;

					//表单
					this.form.truck_plate_nums=this.data.truck_plate_num_list?JSON.parse(this.data.truck_plate_num_list).join(','):''
					this.form.mark=this.data.mark

				}else{

					//关闭弹出层
					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			//提交
			sub(){

				//车牌号
				let truck_plate_nums=this.form.truck_plate_nums.split(',')
				let truck_plate_num_list=[];
				for(var item of truck_plate_nums){
					if(!item.trim()){
						continue;
					}
					if(!this.$my.check.is_plate_num(item.trim())){
						this.$my.other.msg({
							type:'warning',
							str:`车牌号(${item})格式有误,请检查车牌号格式,或者检查逗号是否正确`
						});
						return;
					}
					if(truck_plate_num_list.indexOf(item.trim())!=-1){
						this.$my.other.msg({
							type:'warning',
							str:`车牌号(${item})已经存在`
						});
						return;
					}
					truck_plate_num_list.push(item.trim())
				}

				//调用接口
				this.$my.net.req({
					data:{
						mod:'tord',
						ctr:'tord_batch_edit_by_admin',
						tord_batch_num:this.data.tord_batch_num,
						truck_plate_num_list:truck_plate_num_list,
						mark:this.form.mark,
					},
					callback:(data)=>{

						//通知
						this.$emit("edited");
					}
				});
			},
		}
	}
</script>