 <template>
	<el-dialog top="1vh" width="1000px" :visible.sync="is_show_in_page">
		<el-button type="primary btn_position" size="small" @click="print">打印</el-button>
		<easyPrint tableShow ref="easyPrint">
			<div class="title">理货清单/{{tord_batch_info.customer_user_info?tord_batch_info.customer_user_info.company_name:''}}/{{tord_batch_info.create_time_text}}</div>
			<div class="background">
				<div class="content">
					<div v-for="(item_list,index) in line_list" :key="index" class="line">
						<div v-for="(item,index) in item_list" :key="index" class="item">

							<!-- 总数 -->
							<div v-if="item.type==1" class="all" >{{item.text}}</div>
							
							<!-- 物流园 -->
							<div v-if="item.type==2" class="park" >{{item.text}}</div>

							<!-- 网点 -->
							<div v-if="item.type==3" class="node" ></div>

							<!-- 代理 -->
							<div v-if="item.type==4" class="agent" >
								<div style="width:37%;font-weight:600">{{item.left_text}}</div>
								<div style="width:63%">{{item.right_text}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</easyPrint>
	</el-dialog>
</template>

<script>
	import easyPrint from 'vue-easy-print'
	export default {
		props:{
			is_show:Number,
			tord_batch_info:Object,
		},
		components:{
			easyPrint
		},
		data() {
			return {
				
				//是否显示
				is_show_in_page:false,

				//元素列表
				line_list:[],
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开弹出层
					this.is_show_in_page=true;

					//初始化
					this.init();

				}else{

					//关闭弹出层
					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			//打印
			print(){
				this.$refs.easyPrint.print()
			},

			//初始化
			init(){

				//读取sku
				this.$my.net.req({
					data:{
						mod:'sku',
						ctr:'sku_list_by_admin',
						pretreatment_method:this.tord_batch_info.customer_user_info.pretreatment_method,
					},
					callback:(data)=>{
						
						let buf=[]
						for(var item of data.list){
							if(buf.indexOf(item.transit_name)==-1)buf.push(item.transit_name);
						}

						this.make(buf)
					}
				});
			},

			//初始化
			make(sku_list){

				//运单列表
				this.tord_list=[];
				
				//读取新的运单列表
				this.$my.net.req({
					data:{
						mod:'tord',
						ctr:'tord_list_by_admin',
						is_get_customer_agent_info:1,
						is_get_tord_node_list:1,
						is_get_tord_node_park_info:1,
						tord_batch_num:this.tord_batch_info.tord_batch_num,
						status:2,
						num:10000,
					},
					callback:(data)=>{

						let tord_list={};
						for(var tord_item of data.list){

							//取出发货网点
							let tord_case_node_info
							for(var tord_node_item of tord_item.tord_node_list){
								if(tord_node_item.type==1){
									tord_case_node_info=tord_node_item
									break;
								}
							}

							//取出排序权重
							let park_sorting_weight=1000000-tord_case_node_info.logistics_park_info.sorting_weight
							let node_sorting_weight=1000000-tord_case_node_info.sorting_weight

							//创建物流园节点
							if(!tord_list[park_sorting_weight]){
								tord_list[park_sorting_weight]={
									name:tord_case_node_info.logistics_park_info.name,
									logistics_node_list:{},
								}
							}

							//创建网点节点
							if(!tord_list[park_sorting_weight].logistics_node_list[node_sorting_weight]){//用排序做键名
								tord_list[park_sorting_weight].logistics_node_list[node_sorting_weight]={
									name:tord_case_node_info.logistics_node_name,
									customer_agent_list:{},
								};
							}

							//置入代理和货物信息
							let customer_agent_cargo_info={};
							if(tord_list[park_sorting_weight].logistics_node_list[node_sorting_weight].customer_agent_list[tord_item.customer_agent_num]){
								customer_agent_cargo_info=tord_list[park_sorting_weight].logistics_node_list[node_sorting_weight].customer_agent_list[tord_item.customer_agent_num].cargo_info;
							}
							let this_cargo_info=JSON.parse(tord_item.cargo_changed_contents)
							for(var key in this_cargo_info){
								if(customer_agent_cargo_info[key]){
									customer_agent_cargo_info[key]=parseInt(customer_agent_cargo_info[key])+parseInt(this_cargo_info[key])
								}else customer_agent_cargo_info[key]=parseInt(this_cargo_info[key])
							}
							tord_list[park_sorting_weight].logistics_node_list[node_sorting_weight].customer_agent_list[tord_item.customer_agent_num]={
								// data:tord_item.tord_match_addr,
								data:tord_item.tord_match_addr_show,
								cargo_info:customer_agent_cargo_info,
							}
						}

						//总数
						let cargo_changed_contents_text=""
						let cargo_changed_contents_buf=JSON.parse(JSON.stringify(this.tord_batch_info.cargo_changed_contents))
						for(let cargo_name of sku_list){
							if(cargo_changed_contents_buf[cargo_name]){
								cargo_changed_contents_text+=`${cargo_name}${cargo_changed_contents_buf[cargo_name]} `
								delete(cargo_changed_contents_buf[cargo_name])
							}
						}
						for(var cargo_name in cargo_changed_contents_buf){
							cargo_changed_contents_text+=`${cargo_name}${cargo_changed_contents_buf[cargo_name]} `
						}

						//转换为item列表
						let line_list=[[{
							type:1,
							text:cargo_changed_contents_text.trim()
						}]];
						let height_buf=30;
						let line_list_push=function(item){
							switch(item.type){
								case 2:height_buf+=30;break;
								case 3:height_buf+=10;break;
								case 4:height_buf+=20;break;
							}
							if(height_buf<=670){//
								line_list[line_list.length-1].push(item);
							}else{
								line_list.push([item])
								height_buf=0
							}
						};

						//置入物流园节点
						for(var logistics_park_sorting_weight in tord_list){

							let logistics_park_info=tord_list[logistics_park_sorting_weight]
							line_list_push({
								type:2,//2:物流园名称
								text:logistics_park_info.name,
							});

							//置入网点节点
							for(var logistics_node_num in logistics_park_info.logistics_node_list){

								let logistics_node_info=logistics_park_info.logistics_node_list[logistics_node_num]
								line_list_push({
									type:3,//3:网点名称
									text:logistics_node_info.name
								});

								//置入代理节点
								for(var customer_agent_num in logistics_node_info.customer_agent_list){

									let customer_agent_info=logistics_node_info.customer_agent_list[customer_agent_num]
									let item={
										type:4,//4:代理和货物信息
										left_text:customer_agent_info.data,
										right_text:''
									};
									let customer_agent_cargo_buf=JSON.parse(JSON.stringify(customer_agent_info.cargo_info))
									for(let cargo_name of sku_list){
										if(customer_agent_cargo_buf[cargo_name]){
											item.right_text+=`${customer_agent_cargo_buf[cargo_name]}${cargo_name}`
											delete(customer_agent_cargo_buf[cargo_name])
										}
									}
									for(var cargo_name in customer_agent_cargo_buf){
										item.right_text+=`${customer_agent_cargo_buf[cargo_name]}${cargo_name}`
									}
									line_list_push(item);
								}
							}
						}

						//渲染
						this.line_list=line_list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.btn_position{
		position:absolute;
	}
	.title{
		width:100%;
		text-align:center;
		font-size: 20px;
   		font-weight: 600;
	}
	.background{
		margin-top:10px;
		width:100%;
		overflow-y:auto;
	}
	.content{
		display:flex;
		flex-wrap:wrap;
		align-content:space-between;
		border: 1px solid #eee;
		border-width:1px 0 1px 0;
		padding-top:10px;

		.line{
			height:700px;
			width:23.79394%;
			margin:30px 0.5% 0 0.5%;
			border:1px solid #aaa;
			border-width:0 1px 0 1px;

			.item{
				.all{
					line-height:30px;
					text-align:center;
					font-size:15px;
				}
				.park{
					padding-left:5px;
					line-height:30px;
					font-size:16px;
				}
				.node{
					padding-left:10px;
					line-height:10px;
					height:10px;
				}
				.agent{
					line-height:20px;
					padding-left:15px;
					display:flex;
					justify-content:space-between;
					font-size:14px;
					color:#999;
				}
			}
		}
	}
</style>